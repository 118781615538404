<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">首页</router-link> |
      <router-link to="/about">方案</router-link>
    </nav> -->
    <Header></Header>
    <router-view />
    <div class="help">
      <div
        class="item order"
        @click="orderClick"
        @mouseleave="mouseLeaveOne('order')"
        @mouseover="mouseOverOne('order')"
      >
        <img src="./assets/aside1.png" alt="" />

        <div class="expend-order" v-show="isShowOrder">
          <img src="./assets/aside1.png" alt="" />
          预约演示
        </div>
      </div>
      <div class="item phone" @mouseleave="mouseLeaveOne('phone')" @mouseover="mouseOverOne('phone')">
        <img src="./assets/aside3.png" alt="" />
        <div class="expend-phone" v-show="isShowPhone">
          <img src="./assets/aside3.png" alt="" />
          400-678-3198
        </div>
      </div>
      <div class="item email" @mouseleave="mouseLeaveOne('email')" @mouseover="mouseOverOne('email')">
        <img src="./assets/aside4.png" alt="" />
        <div class="expend-email" v-show="isShowEmail">
          <img src="./assets/aside4.png" alt="" />
          ruifazhong@qq.com
        </div>
      </div>
      <div
        class="item"
        style="position: relative"
        @mouseleave="mouseLeaveOne('service')"
        @mouseover="mouseOverOne('service')"
      >
        <div v-show="isShowService" style="position: absolute; right: 70px; top: 0">
          <img
            style="width: 100px; height: 100px"
            src="https://minio.rfxxkj.com/wxapplet-community/kefuWechat.png"
            alt=""
          />
          <div style="padding: 2px 7px">微信咨询</div>
        </div>

        <img src="./assets/aside5.png" alt="" />
      </div>
      <div v-show="fixed" class="item" @click="backToTop">
        <img src="./assets/aside6.png" alt="" />
      </div>
    </div>
    <Order @closeDialog="closeDialog" v-if="isShowOrderDialog"></Order>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import Order from '@/components/order/index.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    Order,
  },
  data() {
    return {
      isShowOrderDialog: false,
      fixed: false,
      isShowOrder: false,
      isShowPhone: false,
      isShowEmail: false,
      isShowService: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getScrollPosition)
    // let pathstr = this.$route.path;
    // const pathName = this.$route.name
    // console.log(this._isMobile(),'pathstr')
    // let regex = /\/m/;
    //     console.log(regex.test(pathstr),pathstr,'regex.test(pathstr)')
    // if(this._isMobile()) {
    //   console.log(regex.test(pathstr),'regex')
    //   if (!regex.test(pathstr)) {
    //     console.log(pathstr,this.$route.path.indexOf('/m') != -1,'pathstr')
    //       if(this.$route.path === '/') {
    //         pathstr = "/m"
    //       }else if(this.$route.path.indexOf('/m') != -1){
    //         pathstr = this.$route.path
    //       }else {
    //         pathstr = "/m" + this.$route.path
    //         console.log("/m" + this.$route.path,'else pathstr')

    //       }
    //       console.log(pathstr,'pathstr')
    //       // 切换到手机端路由
    //       if(pathstr != this.$route.path) {
    //         this.$router.replace(pathstr);
    //       }
    //   }
    // }else {
    //   if (regex.test(pathstr)) {
    //       let temp = pathstr.match(regex);
    //       console.log(temp,'temp')
    //       if (temp != null) {
    //         if(temp[0] == '/m' || temp[1] === '/') {
    //           pathstr = '/'
    //         }else if(temp[0].indexOf('/m') != -1) {
    //           pathstr = temp[0].replace('/m','')
    //         }
    //         console.log(pathstr,'pc端')
    //       }
    //       // 切换到pc端路由
    //       if(pathstr != this.$route.path) {
    //         this.$router.replace(pathstr);
    //       }
    //   }
    // }
    // if (this._isMobile()) {
    //     //是手机端，需要将页面切换成手机端路由
    //     let regex = /\/pc\/(\S*)/;
    //     //测试是否匹配，进入则说明路由切换到电脑端
    //     if (regex.test(pathstr)) {
    //         let temp = pathstr.match(regex);
    //         if (temp != null) {
    //             pathstr = "/phone/" + temp[1]
    //         }
    //         // 切换到手机端路由
    //         this.$router.replace(pathstr);
    //     }
    // } else {
    //     //是pc端，需要将页面切换到pc端路由
    //     let regex = /\/phone\/(\S*)/;
    //     //测试是否匹配，进入则说明路由切换到手机端
    //     if (regex.test(pathstr)) {
    //         let temp = pathstr.match(regex);
    //         if (temp != null) {
    //             pathstr = "/pc/" + temp[1]
    //         }
    //         // 切换到pc端路由
    //         this.$router.replace(pathstr);
    //     }
    //         }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    closeDialog() {
      this.isShowOrderDialog = false
    },
    orderClick() {
      this.isShowOrderDialog = true
    },
    mouseLeaveOne(type) {
      switch (type) {
        case 'order':
          this.isShowOrder = false
          break
        case 'phone':
          this.isShowPhone = false
          break
        case 'email':
          this.isShowEmail = false
          break
        case 'service':
          this.isShowService = false
          break
      }
    },
    mouseOverOne(type) {
      switch (type) {
        case 'order':
          this.isShowOrder = true
          break
        case 'phone':
          this.isShowPhone = true
          break
        case 'email':
          this.isShowEmail = true
          break
        case 'service':
          this.isShowService = true
          break
      }
    },
    getScrollPosition(el = window) {
      this.y = el.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.y > 0) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    backToTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss">
.help {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 50px;

  // height: 200px;
  background-color: transparent;
  top: 30%;
  right: 15px;
  z-index: 9999;
  // margin: auto;
  .item {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #0e284c;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: #42b983;
    }
    img {
      width: 27px;
      height: 27px;
    }
  }
  .order {
    .expend-order {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      right: 0;
      width: 170px;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 20px;
      background-color: #42b983;
    }
    img {
      width: 27px;
      height: 27px;
    }
  }
  .phone {
    .expend-phone {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      right: 0;
      width: 240px;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 20px;
      background-color: #42b983;
    }
  }
  .email {
    .expend-email {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      right: 0;
      width: 280px;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 20px;
      background-color: #42b983;
    }
  }
}
nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
