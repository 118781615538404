import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    caseActive: 1,
    caseList: [
      {
        title: '古龙公寓',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '古龙公寓.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '信达大厦',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '信达大厦.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '红都桦林苑',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '红都桦林苑.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '红都梅岗',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '红都梅岗.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '红都新康园',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '红都新康园.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '雪梨星光',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '雪梨星光.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '皓晖花园',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '皓晖花园.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '金尚小区',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '金尚小区.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '瑞明保障房',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '瑞明保障房.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '石井保障房小区',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '石井保障房小区.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '象湖湿地公园',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '象湖湿地公园.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
      {
        title: '象湖新城二期',
        imgUrl: process.env.VUE_APP_BASE_IMG_PATH + '象湖新城二期.jpg',
        content: '打造统一收支管理平台，实现集团业财税一体化管控。',
      },
    ],

    programmeList: [],
    productList: [],
    menuList: [],
  },
  getters: {
    caseList: state => state.caseList,
    caseActive: state => state.caseActive,
  },
  mutations: {
    getMenu(state, payload) {
      state.menuList = payload
    },
    getProgrammeMenu(state, payload) {
      state.programmeList = payload
    },
    getProductMenu(state, payload) {
      state.productList = payload
    },
    SETCASELIST(state, payload) {
      state.caseList = payload
    },
    SETCASEACTIVE(state, payload) {
      state.caseActive = payload
    },
  },
  actions: {
    async fetchMenu(context, payload) {
      try {
        const response = await fetch(`https://zhwy.rfxxkj.com/cms/rfchannel/getChannelList?parentId=${payload}`)
        const data = await response.json()
        context.commit('getMenu', data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchProgrammeMenu(context, payload) {
      try {
        const response = await fetch(`https://zhwy.rfxxkj.com/cms/rfchannel/getChannelList?parentId=${payload}`)
        const data = await response.json()
        context.commit('getProgrammeMenu', data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchProductMenu(context, payload) {
      try {
        const response = await fetch(`https://zhwy.rfxxkj.com/cms/rfchannel/getChannelList?parentId=${payload}`)
        const data = await response.json()
        context.commit('getProductMenu', data.data)
      } catch (error) {
        console.error(error)
      }
    },
  },
  modules: {},
})
