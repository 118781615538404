import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import product from './const/product'
import programme from './const/programme'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err=>err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/m',
    name: 'home_m',
    component: HomeView,
  },
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "about" */ '../views/case/index.vue'),
  },
  {
    path: '/m/case',
    name: 'case_m',
    component: () => import(/* webpackChunkName: "about" */ '../views/case/index.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
  },
  {
    path: '/m/about',
    name: 'about_m',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
  },
  {
    path: '/companynews',
    name: 'Companynews',
    component: () => import('../views/companynews/index.vue'),
  },
  {
    path: '/m/companynews',
    name: 'Companynews_m',
    component: () => import('../views/companynews/index.vue'),
  },
  {
    path: '/industrynews',
    name: 'Industrynews',
    component: () => import('../views/industrynews/index.vue'),
  },
  {
    path: '/m/industrynews',
    name: 'Industrynews_m',
    component: () => import('../views/industrynews/index.vue'),
  },
  {
    path: '/companynews/:id',
    name: 'companynewsDetail',
    component: () => import('../views/companynews/detail.vue'),
  },
  {
    path: '/m/companynews/:id',
    name: 'companynewsDetail_m',
    component: () => import('../views/companynews/detail.vue'),
  },
  {
    path: '/industrynews/:id',
    name: 'industrynewsDetail',
    component: () => import('../views/industrynews/detail.vue'),
  },
  {
    path: '/m/industrynews/:id',
    name: 'industrynewsDetail_m',
    component: () => import('../views/industrynews/detail.vue'),
  },
  {
    path: '/cooperate',
    name: 'CooperateView',
    component: () => import(/* webpackChunkName: "cooperate" */ '../views/cooperate/index.vue'),
  },
  {
    path: '/m/cooperate',
    name: 'CooperateView_m',
    component: () => import(/* webpackChunkName: "cooperate" */ '../views/cooperate/index.vue'),
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
router.addRoutes([...product, ...programme])

router.beforeEach((to,from,next)=>{
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    if (to.path.indexOf('/m') === -1) {
      next({path: '/m'+to.path})
    }else {
      next()
    }
   } else {
     if (to.path.indexOf('/m') !== -1) {
        next({path: to.path.replace('/m', '')})
     }else {
      next()
     }
   }
})

router.afterEach((to, from, next) => {
  //回到顶部
  window.scrollTo(0, 0)
})

export default router
