<template>
  <div>
    <div id="swiper-banner">
      <swiper class="swiper" :options="swiperOption11">
        <swiper-slide v-for="item in bannerList" :key="item.id" :class="item.id"
          :style="{'background-image':`url(${item.img})`,color:item.color||'#11d4e1'}">
          <div class="title" data-swiper-parallax="-100">{{item.title}}</div>
          <div class="subtitle" data-swiper-parallax="-240"
            v-for="(sub,subI) in item.subtitle"
            :key="subI"
          >{{sub}}</div>
          <div class="btn-list">
            <div class="ask" @click="dialogVisible = true">立即咨询</div>
            <div @click="orderClick" class="applicate">申请体验</div>
          </div>
        </swiper-slide>
        <!-- <swiper-slide class="slide-2">
          <div class="title" data-swiper-parallax="-100">数智巡检</div>
          <div class="subtitle" data-swiper-parallax="-240">巡检标准化 管理智能化 品质可视化</div>
        </swiper-slide>
        <swiper-slide class="slide-3">
          <div class="title" data-swiper-parallax="-100">数智业委会</div>
          <div class="subtitle" data-swiper-parallax="-240">让委员监督更省心</div>
          <div class="subtitle" data-swiper-parallax="-240">业主生活更舒心</div>
          <div class="subtitle" data-swiper-parallax="-240">小区决策更放心</div>
        </swiper-slide>
        <swiper-slide class="slide-4">
          <div class="title" data-swiper-parallax="-100">数智砂石</div>
          <div class="subtitle" data-swiper-parallax="-240">降低企业运营成本、预防资产流失、提升服务质量</div>
        </swiper-slide> -->
        <!-- <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div> -->
        <!-- <div class="swiper-button-next swiper-button-white" slot="button-next"></div> -->
      </swiper>
      <div v-if="true" class="swiper-pagination mt-30" slot="pagination"></div>
      <div class="statistics">
        <div class="statistics-item" v-for="(item, index) in statisticsList">
          <img :class="item.class" :src="item.imgUrl" />
          <div class="desc">
            <div class="name">
              {{ item.label }}
            </div>
            <div class="number">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top-section">
      <div class="product w-w">
        <IntroTitle class="title-margin" :title="['产品中心', 'PRODUCT CENTER']"></IntroTitle>
        <swiper class="swiper product-list" :options="swiperOption">
          <swiper-slide class="ml" v-for="(item, index) in productList">
            <div class="product-item">
              <div class="product-title">
                <div class="title">
                  {{ item.title }}
                </div>
                <img class="img" :class="item.class" :src="item.imgUrl" alt="" />
              </div>
              <div class="product-content">
                <div class="title">
                  {{ item.value }}
                </div>
                <div class="content">
                  {{ item.content }}
                </div>
                <div class="button" @click="routerPush(item.path)">了解更多</div>
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
          <!-- <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
        <!-- <div class="product-list">
          <div class="product-item" v-for="(item, index) in productList">
            <div class="product-title">
              <div class="title">
                {{ item.title }}
              </div>
              <img class="img" :src="item.imgUrl" alt="" />
            </div>
            <div class="product-content">
              <div class="title">
                {{ item.value }}
              </div>
              <div class="content">
                {{ item.content }}
              </div>
              <div class="button">了解更多</div>
            </div>
          </div>
        </div> -->
      </div>
      <IntroTitle class="title-margin" :title="['解决方案', 'SOLUTION PROGRAM']"></IntroTitle>
      <div class="solution-list">
        <swiper class="swiper" :options="swiperOption2">
          <swiper-slide v-for="(item, index) in solutionList" :key="item.title+index">
            <div class="solution-item">
              <div class="item-left">
                <img class="img" :class="item.class" :src="item.imgUrl" alt="" />
              </div>
              <div class="item-right">
                <div class="title">{{ item.title }}</div>
                <div class="content">{{ item.content }}</div>
                <div class="btn-box">
                  <div class="btn" @click="routerPush(item.path)">了解更多</div>
                </div>
              </div>
            </div>
          </swiper-slide>

          <div v-show="false" class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <IntroTitle class="title-margin" :title="['新闻资讯', 'NEWS']"></IntroTitle>
      <div class="new-list">
        <div class="img-list">
          <div class="img-top">
            <img src="../../assets/img/数智物业left.png" alt="" />
          </div>
          <div class="img-bottom">
            <img src="../../assets/img/数智业委会left.png" alt="" />
          </div>
        </div>
        <div class="industry-news">
          <div class="new-title">
            <div class="title-left">
              <img class="img" src="../../assets/img/智慧街居.png" alt="" />
              <span>行业动态</span>
            </div>
            <div class="title-right" @click="newsPage('industrynews')">More</div>
          </div>
          <div class="new-item" @click="detailPage(item, 'industrynews')" v-for="(item, index) in momentList" :key="index">
            <div class="content">{{ item.title }}</div>
            <div class="date">{{ item.createTime }}</div>
          </div>
        </div>
        <div class="company-new">
          <div class="new-title">
            <div class="title-left">
              <img class="img" src="../../assets/img/智慧街居.png" alt="" />
              <span>公司新闻</span>
            </div>
            <div class="title-right" @click="newsPage('/companynews')">More</div>
          </div>
          <div class="new-item" @click="detailPage(item, 'companynews')" v-for="(item, index) in newsList">
            <div class="content">{{ item.title }}</div>
            <div class="date">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <!-- <IntroTitle :title="['典型客户应用', 'APPLICATIONS']"></IntroTitle>
      <div class="application-list">
        <div class="application-item" v-for="(item, index) in appImgList">
          <img class="app-img" :src="item.imgUrl" />
        </div>
      </div> -->
      <div class="submit-need">
        <div class="submit-title">预约产品演示</div>
        <div class="submit-form">
          <div class="input">
            <div class="name">
              <img src="../../assets/img/姓名.png" alt="" />
              <input v-model="form.personName" maxlength="8" type="text" placeholder="请输入您的姓名" />
            </div>
            <div class="phone">
              <img src="../../assets/img/电话.png" alt="" />
              <input v-model="form.phone" type="number" placeholder="请输入手机号" />
            </div>
          </div>
          <div class="submit" @click="submit">提交需求</div>
          <div class="ask">在线咨询</div>
        </div>
        <div class="contact-phone">联系热线：400-678-3198</div>
      </div>
    </div>
    <Ask v-if="dialogVisible" @closeDialog="dialogVisible=false"></Ask>
    <Order @closeDialog="closeDialog" v-if="isShowOrderDialog"></Order>
  </div>
</template>

<script>
import IntroTitle from './cpns/intro-title.vue'
import { sendPostRequest } from '@/request.js'
import Ask from '@/components/ask/index.vue'
import Order from '@/components/order/index.vue'
export default {
  name: 'HomeView',
  components: {
    Ask,
    Order,
    // Header,
    // Footer,
    IntroTitle,
  },
  data() {
    return {
      bannerList:[{
        id:'slide-1',
        title:'数智物业',
        subtitle:['服务便捷化、管理智能化、创收多元化'],
        img:require('@/assets/img/product/数智物业.png'),
      },{
        id:'slide-2',
        title:'数智巡检',
        subtitle:['巡检标准化 管理智能化 品质可视化'],
        img:require('@/assets/img/case/数智业委会banner.png'),
      },{
        id:'slide-3',
        title:'数智业委会',
        subtitle:['让委员监督更省心','业主生活更舒心','小区决策更放心'],
        img:require('@/assets/img/数智业委会banner.png'),
      },{
        id:'slide-4',
        title:'数智砂石',
        color:'#fff',
        subtitle:['降低企业运营成本、预防资产流失、提升服务质量'],
        img:require('@/assets/img/数智砂石banner.png'),
      }],
      dialogVisible: false,
      isShowOrderDialog: false,
      form: {
        phone: '',
        personName: '',
      },
      activeIndex: '1',
      articleList: [],
      swiperOption11: {
        // spaceBetween: 30,
        // effect: 'fade',
        pagination: {
          el: '#swiper-banner .swiper-pagination',
          clickable: true,
          observer: true, //开启动态检查器，监测swiper和slide
          observeParents: true, //监测Swiper 的祖/父元素
        },
        loop: true,
        autoplay: true,
        navigation: {
          nextEl: '#swiper-banner .swiper-button-next',
          prevEl: '#swiper-banner .swiper-button-prev',
        },
      },
      swiperOption2: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOption: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 30,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.product .swiper-button-next',
          prevEl: '.product .swiper-button-prev',
        },
      },
      appImgList: [
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
        {
          imgUrl: require('@/assets/img/智慧街居.png'),
        },
      ],
      newsList: [],
      momentList: [],
      solutionList: [
        {
          title: '数智物业',
          content:
            '智慧物业平台采用物联网、云计算、移动互联网、人工智能等新兴技术，结合智能门禁、智能道闸、视频监控、智能充电等硬件，与客服、品管、收费、财务、行政、办公、停车、报表、权限等系统深度融合，实现证照智能识别、数据智能填充、服务智能监督、费用智能计算、财务智能核算、流程智能审签、任务智能提醒、报表智能生成，通过服务标准化、业财一体化、过程协同化、运营增值多元化，满足物业公司业务工作需求，从而破解因信息不对称、服务标准不一、投入成本高等原因导致的收费难、融合难、效率低、协作难、收入少的局面，最终实现科技赋能物业公司，无需从零开始，快速实现服务便捷化、管理智能化、创收多元化。',
          imgUrl: require('@/assets/img/智慧资管.png'),
          class: 'solution-img1',
          path: '/programme',
        },
        {
          title: '数智业委会',
          content:
            '数智业委会平台采用物联网、云计算、移动互联网、人工智能等新兴技术，通过线上线下服务可视化、服务标准化、财务数智化、过程协同化、移交一键化，满足业主、委员、物业三方业务工作需求，从而破解因信息不对称、反馈跟踪难、无电子档案等导致的物业服务监督难、公共报修跟踪难、收支建账公示难、小区停车管理难、公共决策执行难、小区资料移交难等问题，最终实现我的数据我做主，提高业委会运作效率，提升物业服务质量，让委员监督更省心、收支建账更安心、业主生活更舒心、小区决策更放心。',
          class: 'solution-img2',
          path: '/programme-ywh',
          imgUrl: require('@/assets/img/智慧社区.png'),
        },
        {
          title: '数智巡检',
          content:
            '数智巡检采用物联网、移动互联网、人工智能等新兴技术，通过巡检点位、巡检标准、巡检线路、巡检任务、安防巡检、品质巡检、装修巡检、设备巡检、巡检工单、移动巡检等功能，深度融合二维码、NFC、蓝牙、GIS定位等技术，实现物业安防、值班、保洁、设备、设施、装修等全场景、全方位巡查、巡检，智能生成巡查记录、巡查报告、品质服务报告等，智能跟踪巡检工单过程，逐步升级品质管控措施，最终实现巡检工作标准化，工单管理智能化，品质管控可视化，让物业服务品质看得见，摸得着，有效化解员工、物业、业主、业委会等多方信任危机。',
          class: 'solution-img3',
          path: '/programme-xunjian',
          imgUrl: require('@/assets/img/智慧园区.png'),
        },
        {
          title: '数智砂石',
          content:
            '数智砂石采用物联网、互联网、人工智能、大数据等新兴技术，结合智能地磅、车牌识别、智能门禁、视频监控、GPS定位终端、红外感应、智能装载等硬件，与电商、订单、生产、配送、财务、仓储、溯源、装载、安防、称重、监管等系统深度融合，实现证照智能识别、数据智能填充、合同智能生成、订单智能审核、流程智能审批、运单智能派送、入场智能开启、计量智能提醒、称重智能上报、超载智能提醒、超速智能预警、溯源智能验证、到货智能确认、库存智能盘点、过程智能监管、运营智能可视，线上线下无缝对接，满足企业客户、企业内部员工、企业领导、运输公司、开采公司等业务及工作需求，有效整合社会各种资源，从而实现矿产资源经营过程的标准化、规范化、智能化，为企业降低运营成本、提高回款效率、减少坏账风险、预防资产流失、提升服务质量。',
          class: 'solution-img4',
          imgUrl: require('@/assets/img/智慧街居.png'),
          path: '/programme-shashi',
        },
      ],
      statisticsList: [
        {
          label: '18年',
          value: '技术积累',
          imgUrl: require('@/assets/img/技术积累.png'),
          class: 'statistics1',
        },
        {
          label: '300+',
          value: '合作企业',
          class: 'statistics2',
          imgUrl: require('@/assets/img/合作企业.png'),
        },
        {
          label: '2000+',
          value: '覆盖项目',
          class: 'statistics3',
          imgUrl: require('@/assets/img/覆盖项目.png'),
        },
        {
          label: '20+',
          value: '核心服务',
          class: 'statistics4',
          imgUrl: require('@/assets/img/核心服务.png'),
        },
      ],
      productList: [
        {
          title: '品质管理',
          value: '',
          content:
            '品质管理通过划分星级服务标准，细化服务内容，设置巡检标准、巡检任务、巡检计划，服务人员及管理人员可通过移动端进行扫码确认、反馈问题、扫码核验等方式完成相应的服务内容，系统智能生成巡检工作报告，并根据不同项目的品质考核指标体系，智能生成品质管理得分，实现服务标准统一化，巡检任务标准化、问题处理可视化、品质考核智能化，为项目经营者、公司管理层提供科学决策数据基础，让客户更加信任，更满意，更放心。',
          imgUrl: require('@/assets/img/品质管理.png'),
          class: 'product-img1',
          path: '/product',
        },
        {
          title: '租售管理',
          value: '',
          content:
            '租售管理围绕房屋、店铺、写字楼、车位、业主、客户、中介等核心要素，通过资产管理、租售发布、身份核验、房屋核实、租售发布、信息分享、联系管家、招商管理、预约确认、租售确认、合同签订、费用支付、资源下架等功能模块，整合电子签章、智能合同、聚合支付、隐私保护等技术，实现不动产资源的出租、出售线上、线下管控一体化、智能化，赋能物业、中介、业主等多方提升不动产租售运营管理能力，为客户创收奠定坚实基础。',
          class: 'product-img1',
          imgUrl: require('@/assets/img/品质管理.png'),
          path: '/product-rent',
        },
        {
          title: '智能合同',
          value: '',
          content:
            '智能合同集成电子签章、人工智能、大数据等技术，通过合同模板、合同拟定、合同审批、合同签署、合同履行、合同收付款、合同变更、合同归档等功能，实现智能生成合同、智能审签合同、智能履约预警、智能收付预警，从而完成合同签订前、签订中、签订后全生命周期的智能化管理。为客户打造内外一体、安全、可信、合规、好用的数智化合同管理模式。',
          class: 'product-img2',
          imgUrl: require('@/assets/img/智能合同.png'),
          path: '/product-contract',
        },
        {
          title: '客户服务',
          value: '',
          content:
            '客户服务产品涵盖呼叫中心、工单管理、公共报事、居家报修、投诉建议、公告消息、车位申请、访客登记、物品放行、装修服务等全方位客户服务场景，实现申请发起、客服接单、服务派单、承办转单、服务处理、催办督办、服务验收、服务评价、客服回访等服务全过程闭环管理，提供微信、公众号、小程序、APP等多终端全渠道形式同步客户服务全过程信息，让客户看得见、摸得着，全程在线感知您的服务，让客服服务有温度更有速度。',
          class: 'product-img3',
          path: 'product-service',
          imgUrl: require('@/assets/img/智能收费.png'),
        },
        {
          title: '收费管理',
          value: '',
          content:
            '收费管理通过物业缴费、预存管理、账单管理、押金管理、停车缴费公共抄表、居家抄表、票据管理、公共收支等功能模块，实现涵盖物业服务费、公摊水电费、装修保证金、停车月租、临时停车、场地使用费、有偿服务费、工本费等收入的精准计算、便捷收款、自动开票、一键催费，支持在线查阅账单、在线支付、扫码支付、POS刷卡、面对面收银、在线预存、押金转物业等支付场景，让住户缴费不再线下跑，省心、省里，助力客户提升收缴率，专注提升服务品质。',
          imgUrl: require('@/assets/img/数智财务.png'),
          path: '/product-charge',
          class: 'product-img4',
        },
        {
          title: '财务管理',
          value: '',
          content:
            '财务管理通过收费项目、生成账单、公摊管理、票据管理、财务退费、交易明细、交易统计、欠费管理、对账管理、费用科目、备用金管理、费用支出、付款管理、公共收支、账单设置、凭证模板、财务报表等功能模块，实现涵盖财务应收、应付、发票、资金、合同、凭证、报表、分析、流程等全业务过程核算智能化、凭证自动化、流程标准化，实现公司级、项目级业务财务一体化智能管理，全面释放财务人员工作量，提升财务分析效率，让经营者随时随地可及时掌握公司经营状况。',
          class: 'product-img1',
          path: '/product-financial',
          imgUrl: require('@/assets/img/品质管理.png'),
        },
        {
          title: '采购管理',
          value: '',
          content:
            '采购管理通过采购需求、询价管理、招投标管理、供应商管理、合同管理、采购订单、物流跟踪、收货管理、质量管理、应付管理、发票管理、财务对账等功能模块，打通了企业与供应商、物流商之间的信息流、资金流与物流的协同，实现了企业与供应商端到端的业务连接；实现企业采购流程化、阳光化、透明化，数字化，助力企业降本增效。',
          class: 'product-img1',
          path: '/product-purchase',
          imgUrl: require('@/assets/img/品质管理.png'),
        },
        {
          title: '商城管理',
          value: '',
          content:
            '此商城为B2B2C商家入驻模式的平台多商户商城系统，系统支持平台自营、联营、招商等多种运营模式，可满足企业新零售、批发、分销、预售、O2O、多店、商铺入驻等各种业务需求。',
          class: 'product-img1',
          path: '/product-shop',
          imgUrl: require('@/assets/img/品质管理.png'),
        },
        {
          title: '停车管理',
          value: '',
          content:
            '智慧停车基于互联网、物联网、云计算、大数据和人工智能等先进技术，通过车牌识别、道闸系统、监控系统、客服系统、车场管理、智能收费、商户管理、优惠管理等功能，实现云对讲、云客服、云识别、云支付、云监管，有效车场运营方的投资成本，人力运营成本，增强车主体验，堵住跑冒滴漏现象漏洞，提高运营收入。',
          class: 'product-img1',
          path: '/product-parking',
          imgUrl: require('@/assets/img/品质管理.png'),
        },
      ],
    }
  },
  async created() {
    try {
      const response = await fetch('https://zhwy.rfxxkj.com/cms/rfarticle/getListByChannelId?channelId=202308150020')
      const data = await response.json()

      this.newsList = this.convertCreateTimeToFormatted(data.data).splice(0, 5)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
    try {
      const response = await fetch('https://zhwy.rfxxkj.com/cms/rfarticle/getListByChannelId?channelId=202308150021')
      const data = await response.json()
      this.momentList = this.convertCreateTimeToFormatted(data.data).splice(0, 5)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  },
  methods: {
    orderClick() {
      this.isShowOrderDialog = true
    },
    closeDialog() {
      this.isShowOrderDialog = false
    },
    submit() {
      if (!this.form.personName) {
        this.$message('请输入姓名')
        return
      }
      if (!this.form.phone) {
        this.$message('请输入电话')
        return
      }
      sendPostRequest('/admin/syscooperation', this.form)
        .then((response) => {
          console.log(response)
          this.$message.success('提交成功')
          this.form = {}
        })
        .catch((error) => {
          console.error(error)
        })
    },
    detailPage(item, type) {
      //路由跳转
      this.$router.push(`/${type}/` + item.id)
    },
    newsPage(item) {
      this.$router.push({
        path: item,
      })
    },
    formatDate(date) {
      const yyyy = date.getFullYear()
      const mm = String(date.getMonth() + 1).padStart(2, '0')
      const dd = String(date.getDate()).padStart(2, '0')
      return `${yyyy}-${mm}-${dd}`
    },
    convertCreateTimeToFormatted(arr) {
      return arr.map((obj) => {
        obj.createTime = this.formatDate(new Date(obj.createTime))
        return obj
      })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    routerPush(path) {
      if (path === this.$route.path) return

      this.$router.push({
        path,
      })
    },
  },
}
</script>

<style lang="scss">
.mt-30 {
  // margin: 0 139px;

  display: flex;
  justify-content: center;
  gap: 20px;
  height: 30px;
  width: 100%;
  position: static;
  line-height: 44px;
  margin-top: -80px;
}
.statistics {
  height: 130px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 5px 7px 0px rgba(154, 160, 179, 0.3);
  margin-left: 141px;
  margin-right: 141px;
  margin-top: -50px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 8;
  .statistics-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px;
    width: 180px;
    min-width: 150px;
    .img {
      width: 76px;
      height: 75px;
    }
    .desc {
      display: flex;
      height: 50px;
      flex-direction: column;
      justify-content: space-between;
      .number {
        color: #9297a2;
        font-size: 16px;
      }
      .name {
        font-size: 20px;
        font-weight: bold;

        color: #283059;
      }
    }
  }
}
.product {
  // height: 400px;
  .swiper {
    // height: 601px;
    // height: 301px;

    .swiper-slide {
      display: flex;
      flex-direction: column;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100%;
      // height: 601px;
      justify-content: center;
    }
  }
  .product-list {
    display: flex;
    justify-content: space-between;
    margin-left: 141px;
    margin-right: 141px;
    .product-item {
      width: 253px;
      height: 260px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 20px;
      .product-title {
        width: 100%;
        height: 50px;
        background-image: url('../../assets/img/产品顶部.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: white;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 18px;
          margin-left: 15px;
        }
        .img {
          margin-right: 15px;
        }
      }
      .product-content {
        // height: 312px;

        width: 100%;
        padding: 30px 15px;
        .title {
          font-size: 20px;
        }
        .content {
          // height: 200px;

          font-size: 18px;
          line-height: 32px;
          color: grey;
          margin-top: 10px;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          width: 224px;
          // padding-left: 20px;
        }
        .button {
          display: inline-block;
          margin-top: 30px;
          background: #ffffff;
          color: #59c4b0;
          // padding: 5px 10px;
          width: 91px;
          height: 30px;
          line-height: 30px;
          border: 0.7px solid #59c4b0;
          text-align: center;
          border-radius: 6px;
          &:hover {
            cursor: pointer;
            background-color: #59c4b0;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.solution-list {
  display: flex;
  align-items: center;
  margin-left: 139px;
  margin-right: 140px;
  flex-wrap: wrap;
  // gap: 13px;
  background-color: #fff;
  .swiper {
    width: 1079px;
    height: 449px;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 517px !important;
      height: 212px;

      justify-content: center;
      margin-right: 18px !important;
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }
  @media screen and (max-width: 768px) {
  }
  .solution-item {
    width: 526px;
    height: 212px;
    background: #fafdfd;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-left {
      margin-left: 31px;
      margin-right: 31px;
      .img {
      }
    }
    .item-right {
      position: relative;
      // height: 150px;
      // margin: 0 13px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      margin-right: 27px;
      .title {
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: normal;
      }
      .content {
        font-size: 16px;
        line-height: 24px;
        color: grey;
        // height: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .btn-box {
        display: flex;
        justify-content: flex-end;
        .btn {
          width: 92px;
          height: 30px;
          margin-top: 30px;
          background: #ffffff;
          color: #59c4b0;
          text-align: center;
          line-height: 30px;
          border: 0.7px solid #59c4b0;
          border-radius: 6px;
          font-size: 14px;
          &:hover {
            cursor: pointer;
            background-color: #59c4b0;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.new-list {
  display: flex;
  justify-content: space-between;
  margin-left: 139px;
  margin-right: 139px;
  padding-bottom: 40px;
  .img-list {
    width: 32%;
    .img-top {
      margin-bottom: 28px;
    }
    .img-top,
    .img-bottom {
      img {
        width: 338px;
        // height: 160px;
        object-fit: contain;
      }
    }
  }
  .company-new {
    box-sizing: border-box;
    width: 353px;
    height: 337px;
    background: #f8f8f8;
    border: 1px solid #e5e5e5;
    padding: 19px 23px 26px 17px;
    .new-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .title-left {
        display: flex;
        align-items: center;
        font-size: 20px;
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .title-right {
        line-height: 30px;
        font-size: 20px;
        color: #59c4b0;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .new-item {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      line-height: 50px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
      }
      .date {
      }
    }
  }
  .industry-news {
    box-sizing: border-box;
    width: 353px;
    padding: 19px 23px 26px 17px;
    height: 337px;
    background: #f8f8f8;
    border: 1px solid #e5e5e5;
    .new-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .title-left {
        display: flex;
        align-items: center;
        font-size: 20px;
        img {
          margin-right: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .title-right {
        line-height: 30px;
        font-size: 19px;
        color: #59c4b0;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .new-item {
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      font-size: 18px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
      }
      .date {
      }
    }
  }
}
.application-list {
  display: flex;
  justify-content: space-between;
  margin: 15px 139px;
  .application-item {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70px;
      height: 70px;
    }
  }
}
.submit-need {
  height: 260px;
  background-color: #383c50;
  background-image: url('../../assets/img/预约产品演示bg@2x.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .submit-title {
    font-size: 30px;
    color: white;
  }
  .submit-form {
    display: flex;
    margin: 30px 0;
    .input {
      display: flex;
      // display: inline-block;
      width: 392px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      // height: 35px;
      background-color: #fff;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 10px;
        left: 15px;
        right: 5px;
      }
      .name {
        width: 180px;
        display: flex;
        input {
          // height: 30px;
          // width: 160px;
          outline: none;
          border-top-left-radius: 6px;
          border: none;
          line-height: 35px;
          margin-left: 20px;
        }
      }
      .phone {
        width: 80px;
        display: flex;

        input {
          // height: 30px;
          // width: 160px;
          outline: none;
          border-top-left-radius: 6px;
          border: none;
          line-height: 35px;
          margin-left: 20px;
        }
      }
    }
    .ask {
      display: inline-block;
      // width: 92px;
      // height: 35px;
      margin-left: 20px;
      border: 1px solid white;
      border-radius: 6px;
      color: white;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      font-size: 14px;
      padding: 2px 20px;
    }
    .submit {
      display: inline-block;
      // width: 92px;
      // height: 35px;
      background: #59c4b0;
      // border: 1px solid #488efd;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      color: white;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      padding: 2px 20px;
      font-size: 14px;
    }
  }
  .contact-phone {
    font-size: 30px;
    color: white;
  }
}
.top-section {
  width: 100%;
  // background: #f8f8f8;
}
.w-w {
  width: 100%;
  background-color: #f8f8f8;
  padding-bottom: 20px;
}

.ml {
  margin-right: 40px;
}

#swiper-banner {
  background-color: #f8f8f8;

  .swiper {
    height: 601px;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 601px;
      justify-content: center;
      .title {
        margin-bottom: 20px;
        font-size: 64px;
        font-weight: normal;
        margin-left: 200px;
      }

      .subtitle {
        margin-bottom: 20px;
        font-size: 26px;
        margin-left: 200px;
      }

      .text {
        max-width: 430px;
        line-height: 1.32;
      }
      .btn-list{
        margin-left: 200px;
        display: flex;
        font-size: 14px;
        .ask {
          width: 96px;
          height: 30px;
          background: #59c4b0;
          border-radius: 6px;
          text-align: center;
          line-height: 30px;
          margin-right: 40px;
          color: #ffffff;
          cursor: pointer;
        }
        .applicate {
          width: 96px;
          height: 29px;
          border: 1px solid #50e3c7;
          border-radius: 6px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
          color: #50e3c7;
        }
      }
    }
  }
}

.statistics1 {
  width: 76px;
  height: 75px;
}
.statistics2 {
  width: 74px;
  height: 76px;
}
.statistics3 {
  width: 75px;
  height: 75px;
}
.statistics4 {
  width: 85px;
  height: 76px;
}
.product-img1 {
  width: 30px;
  height: 38px;
}
.product-img2 {
  width: 32px;
  height: 31px;
}
.product-img3 {
  width: 35px;
  height: 34px;
}
.product-img4 {
  width: 44px;
  height: 32px;
}
.solution-img1 {
  width: 96px;
  height: 97px;
  object-fit: contain;
}
.solution-img2 {
  width: 96px;
  object-fit: contain;
  height: 107px;
}
.solution-img3 {
  width: 108px;
  object-fit: contain;
  height: 107px;
}
.solution-img4 {
  width: 96px;
  object-fit: contain;
  height: 103px;
}
.title-margin {
  padding-top: 41px;
  padding-bottom: 31px;
}
</style>
