export default [
  {
    path: '/programme',
    name: 'programme',

    component: () => import(/* webpackChunkName: "wuye" */ '@/views/programme/wuye/index.vue'),
  },
  {
    path: '/m/programme',
    name: 'programme_m',

    component: () => import(/* webpackChunkName: "wuye" */ '@/views/programme/wuye/index.vue'),
  },
  {
    path: '/programme-ywh',
    name: 'programme-ywh',

    component: () => import(/* webpackChunkName: "ywh" */ '@/views/programme/ywh/index.vue'),
  },
  {
    path: '/m/programme-ywh',
    name: 'programme-ywh_m',

    component: () => import(/* webpackChunkName: "ywh" */ '@/views/programme/ywh/index.vue'),
  },
  {
    path: '/programme-xunjian',
    name: 'programme-xunjian',
    component: () => import(/* webpackChunkName: "xunjian" */ '@/views/programme/xunjian/index.vue'),
  },
  {
    path: '/m/programme-xunjian',
    name: 'programme-xunjian_m',
    component: () => import(/* webpackChunkName: "xunjian" */ '@/views/programme/xunjian/index.vue'),
  },
  {
    path: '/programme-shashi',
    name: 'programme-shashi',
    component: () => import(/* webpackChunkName: "xunjian" */ '@/views/programme/shashi/index.vue'),
  },
  {
    path: '/m/programme-shashi',
    name: 'programme-shashi_m',
    component: () => import(/* webpackChunkName: "xunjian" */ '@/views/programme/shashi/index.vue'),
  },
]
