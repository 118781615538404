import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import './assets/css/index.css'
import 'element-ui/lib/theme-chalk/index.css'

import {
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Dialog,
  Form,
  FormItem,
  Row,
  Col,
  Checkbox,
  CheckboxGroup,
  Button,
  ButtonGroup,
  Input,
  InputNumber,
  Pagination,
  Message
} from 'element-ui'
//注册轮播图插件
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)

Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Pagination)


Vue.prototype.$baseImgBase = process.env.VUE_APP_BASE_IMG_PATH
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
