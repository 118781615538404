<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="30%" @close="closeDialog" :title="'微信咨询'">
          <div class="img-box">
            <img src="https://minio.rfxxkj.com/wxapplet-community/kefuWechat.png" alt="" />
          </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible:true,
            }
        },
        methods:{
            closeDialog() {
                this.dialogVisible = false
                this.$emit('closeDialog')
            },
        }
    }
</script>

<style scoped lang="scss">
.img-box {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  img {
    width: 150px;
    height: 150px;
    text-align: center;
  }
}
</style>