<template>
  <header :class="[fixed ? 'fixed' : 'transparent']">
    <div class="logo"></div>

    <el-menu
      :default-active="$route.path"
      text-color="#fff"
      active-text-color="#2EF1F6"
      :class="[fixed ? 'fixed' : 'transparent']"
      mode="horizontal"
      router
    >
      <el-menu-item :index="menuList[0].channelPath">
        <a href="javascript:void(0)">{{ menuList[0].channelName }}</a>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <a href="javascript:void(0)">{{ menuList[1].channelName }}</a>
        </template>
        <el-menu-item :index="item.channelPath" v-for="(item, index) in programmeList" :key="index">
          {{ item.channelName }}
        </el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <a href="javascript:void(0)">{{ menuList[2].channelName }}</a>
        </template>
        <el-menu-item :index="item.channelPath" v-for="(item, index) in productList">
          {{ item.channelName }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item :index="menuList[3].channelPath">
        <a href="javascript:void(0)">{{ menuList[3].channelName }}</a>
      </el-menu-item>
      <el-menu-item :index="menuList[4].channelPath">
        <a href="javascript:void(0)">{{ menuList[4].channelName }}</a>
      </el-menu-item>
      <el-menu-item :index="menuList[5].channelPath">
        <a href="javascript:void(0)">{{ menuList[5].channelName }}</a>
      </el-menu-item>
    </el-menu>
    <div class="contact-phone">
      <div class="phone-logo"></div>
      <span>400-678-3198</span>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentIndex: 0,
      timer: null,
      y: '1',
      fixed: false,
      activeIndex: '1',
    }
  },
  computed: {
    ...mapState({
      productList: (state) => state.productList,
      programmeList: (state) => state.programmeList,
      menuList: (state) => state.menuList,
    }),
  },
  methods: {
    getScrollPosition(el = window) {
      this.y = el.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.y > 0) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
  },
  async created() {
    this.$store.dispatch('fetchMenu', 202308170053)
    this.$store.dispatch('fetchProductMenu', 2023081700000001)
    this.$store.dispatch('fetchProgrammeMenu', 202308170057)
  },
  mounted() {
    window.addEventListener('scroll', this.getScrollPosition)

    this.timer = setInterval(() => {
      if (this.$route.meta.isShowBtn) {
        if (this.currentIndex > 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex = this.currentIndex + 1
        }
      }
    }, 2500)
  },
}
</script>

<style>
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 145px !important;
}
.el-submenu__title {
  font-size: 16px !important;
}
.el-menu--popup-bottom-start li {
  color: #333333 !important;
  font-size: 16px !important;
  text-align: center;
  &:hover {
    color: #fff !important;
    background-color: #11d4e1 !important;
  }
}
.el-menu--popup-bottom-start .el-submenu .el-submenu__title {
  color: #333333 !important;
  font-size: 16px !important;
}
.el-menu--popup-bottom-start .el-submenu .el-submenu__title:hover {
  background-color: #eaf5ff !important;
  color: #53b1fd !important;
}
.el-menu-item:hover {
  outline: 0 !important;
  color: #11d4e1 !important;
  background: transparent !important;
}
.el-menu-item.is-active {
  color: #11d4e1 !important;
  background: transparent !important;
}
.el-submenu__title i {
  color: #fff !important;
}
.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0 !important;
  color: #11d4e1 !important;
  background: transparent !important;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background: transparent !important;
}
</style>
<style lang="scss" scoped>
.el-menu.el-menu--horizontal {
  border: none;
  margin-left: 200px;
}

@media screen and (max-width: 768px) {
  .el-menu.el-menu--horizontal {
    border: none;
    margin-left: 100px;
  }
}
header {
  z-index: 999;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  ul {
    li {
      font-size: 16px;
      a {
        color: #fff;
      }
    }
  }
  .transparent {
    background-color: transparent;
    // margin-left: 130px;
  }
  .logo {
    width: 184px;
    height: 38px;
    background-size: 100% 100%;
    background-image: url('../../assets/img/-h-睿法科技logo1.png');
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 141px;
    // margin-right: 340px;
  }
  .contact-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 141px;
    margin-left: auto;
    font-size: 20px;
    color: #fff;
    .phone-logo {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image: url('../../assets/img/顶部电话.png');
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }
  // height: 80px;
}

.fixed {
  background-color: #080437;
  z-index: 999;
  border-bottom: 1px solid black;
  // margin-left: 130px;
}
</style>
