export async function sendPostRequest(url, data) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error(`${response.status}`)
    }

    const jsonResponse = await response.json()
    return jsonResponse
  } catch (error) {
    console.error(error)
    throw error
  }
}
