<template>
  <div class="order">
    <el-dialog @close="closeDialog" top="5vh" title="" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" :rules="rules">
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15" align="middle">
            <div class="order-title">在线演示</div>
            <div class="desc">完善信息后，将会有客服人员为您在线演示</div>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15" align="middle">
            <el-form-item label="" prop="phone">
              <el-input v-model="form.phone" type="number" placeholder="请输入您的手机号" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15" align="middle">
            <el-form-item class="validate-item" label="">
              <el-input v-model="form.code" placeholder="请输入您的验证码" autocomplete="off">
                <!-- <div class="validate">获取验证码</div> -->
                <template slot="append">
                  <el-button style="background-color: #66b1ff; color: white" type="primary">获取验证码</el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-form-item label="" prop="personName">
              <el-input v-model="form.personName" maxlength="8" placeholder="您的姓名" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-form-item label="">
              <el-input v-model="form.enterpriseName" placeholder="您的公司名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-form-item label="">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                placeholder="您的需求"
                v-model="form.requireInfo"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-checkbox v-model="checked">
              我已阅读并同意
              <span @click="viewInfo">《服务协议》</span>
            </el-checkbox>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px" type="flex" justify="center" align="middle">
          <el-col :span="15">
            <el-button round style="width: 100%" :disabled="!checked" type="primary" @click="send('form')">
              提交
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div> -->
      <el-dialog top="5vh" width="60%" title="用户协议" :visible.sync="innerVisible" append-to-body>
        <div class="user-xieyi">
          <div class="head">服务协议</div>
          <ul class="p1">
            <li>尊敬的用户，欢迎您注册成为本网站用户。在注册前请您仔细阅读如下服务条款：</li>
            <li>本服务协议双方为本网站与本网站用户，本服务协议具有合同效力。</li>
            <li>
              您确认本服务协议后，本服务协议即在您和本网站之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。
            </li>
            <li>
              无论您事实上是否在注册之前认真阅读了本服务协议，只要您按照本网站注册程序成功注册为用户，您的行为仍然表示您同意并签署了本服务协议。
            </li>
          </ul>
          <div class="p2">
            <div class="title">1．本网站服务条款的确认和接纳</div>
            <ul>
              <li>本网站各项服务的所有权和运作权归本网站拥有。</li>
            </ul>
            <div class="title">2．用户必须：</div>
            <ul>
              <li>(1)自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。</li>
              <li>(2)自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</li>
            </ul>
            <div class="title">3．用户在本网站平台上不得发布下列违法信息：</div>
            <ul>
              <li>(1)反对宪法所确定的基本原则的；</li>
              <li>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
              <li>(3)损害国家荣誉和利益的；</li>
              <li>(4)煽动民族仇恨、民族歧视，破坏民族团结的；</li>
              <li>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
              <li>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
              <li>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
              <li>(8)侮辱或者诽谤他人，侵害他人合法权益的；</li>
              <li>(9)含有法律、行政法规禁止的其他内容的。</li>
            </ul>
            <div class="title">4．有关个人资料</div>
            <ul>
              <li>用户同意：</li>
              <li>(1) 提供及时、详尽及准确的个人资料。</li>
              <li>(2).同意接收来自本网站的信息。</li>
              <li>(3)损害国家荣誉和利益的；</li>
              <li>
                (4)本网站不公开用户的姓名、地址、电子邮箱和笔名，以下情况除外：
                <ul>
                  <li>（a）用户授权本网站透露这些信息。</li>
                  <li>
                    （b）相应的法律及程序要求本网站提供用户的个人资料。如果用户提供的资料包含有不正确的信息，本网站保留结束用户使用本网站信息服务资格的权利。
                  </li>
                </ul>
              </li>
              <li>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
              <li>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
              <li>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
              <li>(8)侮辱或者诽谤他人，侵害他人合法权益的；</li>
              <li>(9)含有法律、行政法规禁止的其他内容的。</li>
            </ul>
            <div class="title">5. 服务条款的修改</div>
            <ul>
              <li>
                本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。
              </li>
            </ul>
            <div class="title">6．用户隐私制度</div>
            <ul>
              <li>
                尊重用户个人隐私是本网站的一项基本政策。所以，本网站一定不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本网站中的非公开内容，除非有法律许可要求或本网站在诚信的基础上认为透露这些信息在以下四种情况是必要的：
              </li>
              <li>(1) 遵守有关法律规定，遵从本网站合法服务程序。</li>
              <li>(2) 保持维护本网站的商标所有权。</li>
              <li>(3) 在紧急情况下竭力维护用户个人和社会大众的隐私安全。</li>
              <li>(4)符合其他相关的要求。 本网站保留发布会员人口分析资询的权利。</li>
            </ul>
            <div class="title">7．用户的帐号、密码和安全性</div>
            <ul>
              <li>
                你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通告本网站。
              </li>
            </ul>
            <div class="title">8．拒绝提供担保 用户明确同意信息服务的使用由用户个人承担风险。</div>
            <ul>
              <li>
                本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。
              </li>
            </ul>
            <div class="title">9．有限责任</div>
            <ul>
              <li>
                本网站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。
              </li>
            </ul>
            <div class="title">10．信息的储存及限制</div>
            <ul>
              <li>
                本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权中断其服务的帐号。
              </li>
            </ul>
            <div class="title">11．用户管理 用户必须遵循：</div>
            <ul>
              <li>(1) 使用信息服务不作非法用途。</li>
              <li>(2) 不干扰或混乱网络服务。</li>
              <li>
                (3)
                遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。
              </li>
            </ul>
            <div class="title">12．保障</div>
            <ul>
              <li>
                用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。
              </li>
            </ul>
            <div class="title">13．结束服务</div>
            <ul>
              <li>
                用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利：
              </li>
              <li>(1) 不再使用本网站信息服务。</li>
              <li>
                (2) 通知本网站停止对该用户的服务。
                结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
              </li>
            </ul>
            <div class="title">14．通告</div>
            <ul>
              <li>
                所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
              </li>
            </ul>
            <div class="title">15．信息内容的所有权</div>
            <ul>
              <li>
                本网站定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
              </li>
            </ul>
            <div class="title">16．法律</div>
            <ul>
              <li>
                本网站信息服务条款要与中华人民共和国的法律解释一致。用户和本网站一致同意服从本网站所在地有管辖权的法院管辖。如发生本网站服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户的约束力。
              </li>
            </ul>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { sendPostRequest } from '@/request.js'
export default {
  data() {
    return {
      checked: false,
      innerVisible: false,
      isShowInfo: false,
      dialogFormVisible: true,
      form: {
        enterpriseName: '',
        personName: '',
        phone: '',
        email: '',
        requireInfo: '',
      },
      rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { min: 11, max: 13, message: '手机号长度必须在11到13个字符', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        personName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
      },
      formLabelWidth: '120px',
    }
  },

  methods: {
    send(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          sendPostRequest('/admin/syscooperation', this.form)
            .then((response) => {
              console.log(response)
              this.$message.success('提交成功')
              this.dialogFormVisible = false
            })
            .catch((error) => {
              console.error(error)
              this.dialogFormVisible = false
            })
        }
      })
    },

    closeDialog() {
      this.dialogFormVisible = false
      this.$emit('closeDialog')
    },
    viewInfo() {
      this.innerVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.validate-item {
  .el-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
.el-dialog__header {
  border-bottom: solid 1px #ccc !important;
}
v::deep .el-dialog__wrapper {
  overflow: none !important;
}
.validate {
  display: inline-block;
  // width: 92px;
  // height: 35px;
  background: #59c4b0;
  // border: 1px solid #488efd;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  padding: 2px 20px;
  font-size: 14px;
}
.user-xieyi {
  max-height: 70vh;
  overflow-y: auto;
  font-size: 14px;
  overflow-x: hidden;
  padding-right: 10px;
  .head {
    font-size: 14px;
    color: #666;
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
  }
  .p1 {
    margin-bottom: 30px;
    li {
      font-size: 14px;
      color: #4c4c4c;
      margin-bottom: 5px;
      line-height: 20px;
    }
  }
  .p2 {
    .title {
      margin-top: 30px;
      font-weight: bold;
    }
    ul {
      li {
        line-height: 20px;
        margin-top: 5px;
        ul {
          li {
            line-height: 20px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
.order-title {
  font-size: 28px;
}
.desc {
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
}
</style>
