export default  [
  {
    path: '/product',
    name: 'product',

    component: () => import(/* webpackChunkName: "product" */ '@/views/product/quality/index.vue'),
  },
  {
    path: '/m/product',
    name: 'product_m',

    component: () => import(/* webpackChunkName: "product" */ '@/views/product/quality/index.vue'),
  },
  {
    path: '/product-rent',
    name: 'product-rent',

    component: () => import(/* webpackChunkName: "rent" */ '@/views/product/rent/index.vue'),
  },
  {
    path: '/m/product-rent',
    name: 'product-rent_m',

    component: () => import(/* webpackChunkName: "rent" */ '@/views/product/rent/index.vue'),
  },
  {
    path: '/product-contract',
    name: 'product-contract',

    component: () => import(/* webpackChunkName: "contract" */ '@/views/product/contract/index.vue'),
  },
  {
    path: '/m/product-contract',
    name: 'product-contract_m',

    component: () => import(/* webpackChunkName: "contract" */ '@/views/product/contract/index.vue'),
  },
  {
    path: '/product-service',
    name: 'product-service',

    component: () => import(/* webpackChunkName: "service" */ '@/views/product/service/index.vue'),
  },
  {
    path: '/m/product-service',
    name: 'product-service_m',

    component: () => import(/* webpackChunkName: "service" */ '@/views/product/service/index.vue'),
  },
  {
    path: '/product-charge',
    name: 'product-charge',

    component: () => import(/* webpackChunkName: "charge" */ '@/views/product/charge/index.vue'),
  },
  {
    path: '/m/product-charge',
    name: 'product-charge_m',

    component: () => import(/* webpackChunkName: "charge" */ '@/views/product/charge/index.vue'),
  },
  {
    path: '/product-financial',
    name: 'product-financial',

    component: () => import(/* webpackChunkName: "financial" */ '@/views/product/financial/index.vue'),
  },
  {
    path: '/m/product-financial',
    name: 'product-financial_m',

    component: () => import(/* webpackChunkName: "financial" */ '@/views/product/financial/index.vue'),
  },
  {
    path: '/product-collaboration',
    name: 'product-collaboration',

    component: () => import(/* webpackChunkName: "collaboration" */ '@/views/product/collaboration/index.vue'),
  },
  {
    path: '/m/product-collaboration',
    name: 'product-collaboration_m',

    component: () => import(/* webpackChunkName: "collaboration" */ '@/views/product/collaboration/index.vue'),
  },
  {
    path: '/product-purchase',
    name: 'product-purchase',

    component: () => import(/* webpackChunkName: "purchase" */ '@/views/product/purchase/index.vue'),
  },
  {
    path: '/m/product-purchase',
    name: 'product-purchase_m',

    component: () => import(/* webpackChunkName: "purchase" */ '@/views/product/purchase/index.vue'),
  },
  {
    path: '/product-shop',
    name: 'product-shop',

    component: () => import(/* webpackChunkName: "shop" */ '@/views/product/shop/index.vue'),
  },
  {
    path: '/m/product-shop',
    name: 'product-shop_m',

    component: () => import(/* webpackChunkName: "shop" */ '@/views/product/shop/index.vue'),
  },
  {
    path: '/product-parking',
    name: 'product-parking',

    component: () => import(/* webpackChunkName: "parking" */ '@/views/product/parking/index.vue'),
  },
  {
    path: '/m/product-parking',
    name: 'product-parking_m',

    component: () => import(/* webpackChunkName: "parking" */ '@/views/product/parking/index.vue'),
  },
]
