<template>
  <footer class="footer">
    <div class="company-info-list">
      <div class="info-left">
        <div class="logo"></div>
        <div class="phone-area">
          <div class="icon">
            <img src="../../assets/img/顶部电话.png" alt="" />
          </div>
          <div class="phone">
            <div class="phone1">售前电话：400-678-3198</div>
            <div class="phone2">售后电话：400-678-3198</div>
          </div>
        </div>
        <div class="address-area">
          <div class="icon">
            <img src="../../assets/img/地址.png" alt="" />
          </div>
          <div class="address">联系地址：厦门集美区软件园三期C区C10栋1102-5</div>
        </div>
        <div class="we-code">
          <div class="left-code">
            <img class="rfkj" src="../../assets/img/睿法科技.png" alt="" />
            <!-- <span class="text">关注“睿法科技”了解更多行业资讯</span> -->
          </div>
          <div class="right-code">
            <img class="mll" src="../../assets/img/美邻里.png" alt="" />
            <!-- <span class="text">关注“美邻里”了解更多社区服务</span> -->
          </div>
        </div>
      </div>
      <div class="info-right">
        <div class="info-list">
          <div class="solution">
            <div class="title">解决方案</div>
            <ul class="nav-item">
              <li v-for="(item) in programmeList" :key="item.id" @click="navigateTo(item)">{{ item.channelName }}</li>
            </ul>
          </div>
          <div class="example">
            <div class="title">客户案例</div>
            <ul class="nav-item">
              <li v-for="(item) in caseSelect" :key="item.index" @click="navigateToCase('/case', item, 'case')">
                {{ item.label }}
              </li>
            </ul>
          </div>
          <div class="example">
            <div class="title">产品中心</div>
            <ul class="nav-item">
              <li v-for="(item) in productList" :key="item.id" @click="navigateTo(item)">{{ item.channelName }}</li>
            </ul>
          </div>
          <div class="about-us">
            <div class="title">关于我们</div>
            <ul class="nav-item">
              <li @click="navigateToAbout('/about', 'info')">公司简介</li>
              <li @click="navigateToAbout('/about', 'qualification')">荣誉资质</li>
              <li @click="navigateToAbout('/about', 'partner')">合作伙伴</li>
              <li @click="navigateToAbout('/about', 'contact')">联系我们</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="company-bottom">
      <div class="link">
        友情链接：
        <span v-for="item in linkList" :key="item.id" class="link-item" @click="openNewWindows(item.url)">{{ item.label }}</span>
      </div>
      <div class="beian">
        <img src="../../assets/beian.png" alt="" />
        <span
          style="cursor: pointer"
          @click="
            openNewWindows('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35021102001886')
          "
        >
          闽公网安备 35021102001886号
        </span>
        <span style="cursor: pointer" @click="openNewWindows('http://beian.miit.gov.cn/')">
          &nbsp;&nbsp;闽ICP备20005737号-1
        </span>
        <span>&nbsp;&nbsp;客服电话：400-678-3198</span>
      </div>
      <div class="copyright">copyright © 2019-2023 睿法信息科技（厦门）有限公司</div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      caseSelect: [
        {
          label: '品质管理',
          index: 1,
          list: [
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_1.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_2.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_3.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_3.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
        {
          label: '收费管理',
          index: 2,
          list: [
            {
              title: '新大正物业2',
              imgUrl: this.$baseImgBase + '图层 62_1.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
        {
          label: '客服管理',
          index: 3,
          list: [
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_2.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
        {
          label: '停车管理',
          index: 4,
          list: [
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_3.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
        {
          label: '采购管理',
          index: 5,
          list: [
            {
              title: '新大正物业',
              imgUrl: this.$baseImgBase + '图层 62_2.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业55',
              imgUrl: this.$baseImgBase + '图层 62_3.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
        {
          label: '数智业委会',
          index: 6,
          list: [
            {
              title: '新大正物业6',
              imgUrl: this.$baseImgBase + '图层 62_1.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业22',
              imgUrl: this.$baseImgBase + '图层 62_2.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
            {
              title: '新大正物业1',
              imgUrl: this.$baseImgBase + '图层 62_3.png',
              content: '打造统一收支管理平台，实现集团业财税一体化管控。',
            },
          ],
        },
      ],
      navigatorList: [
        [
          {
            label: '数智物业',
            pathNum: '2-1',
          },
          {
            label: '数智业委会',
            pathNum: '2-2',
          },
          {
            label: '数智巡检',
            pathNum: '2-5',
          },
          {
            label: '数智砂石',
            pathNum: '2-6',
          },
        ],
        [
          {
            label: '品质管理',
            pathNum: '3-1',
          },
          {
            label: '租售管理',
            pathNum: '3-2',
          },
          {
            label: '智能合同',
            pathNum: '3-3',
          },
          {
            label: '客户服务',
            pathNum: '3-4',
          },
          // {
          //   label:'数据中台',
          //   pathNum:'3-5'
          // },
          {
            label: '收费管理',
            pathNum: '3-6',
          },
          {
            label: '财务管理',
            pathNum: '3-7',
          },
          // {
          //   label: '协同办公',
          //   pathNum: '3-8',
          // },
          {
            label: '采购管理',
            pathNum: '3-9',
          },
          {
            label: '商城管理',
            pathNum: '3-10',
          },
          // {
          //   label:'设备管理',
          //   pathNum:'3-11'
          // },
          // {
          //   label:'数智物联',
          //   pathNum:'3-12'
          // },
          {
            label: '停车管理',
            pathNum: '3-13',
          },
        ],
      ],
      linkList:[{
        id:1,
        label:'日新网',
        url:'https://rixins.com/'
      },{
        id:2,
        label:'中国物业管理协会',
        url:'https://www.ecpmi.org.cn/'
      },{
        id:3,
        label:'福建物业管理协会',
        url:'http://www.fjwygl.org.cn/'
      },{
        id:4,
        label:'数智物业-知乎',
        url:'https://www.zhihu.com/people/slaw-96'
      },{
        id:5,
        label:'数智物业-头条',
        url:'https://www.toutiao.com/c/user/token/MS4wLjABAAAA-U97F4CPyUjcHm-qeiHsJNokaJB9KkHkehdpeHSNLWzwfX72QdNCUFcsMkHrQw5E/?wid=1725582576461'
      }]
    }
  },
  computed: {
    ...mapState({
      productList: (state) => state.productList,
      programmeList: (state) => state.programmeList,
      menuList: (state) => state.menuList,
    }),
  },
  methods: {
    openNewWindows(link) {
      window.open(link, '_blank')
    },
    navigateTo(item) {
      this.routerPush(item.channelPath)
    },
    navigateToCase(path, item, Id) {
      this.$store.state.caseActive = item.index
      this.$store.state.caseList = item.list
      if (path == this.$route.path) {
        //如果当前已经在这个页面不跳转 直接去
        let toElement = document.getElementById(Id)
        toElement.scrollIntoView(true)
      } else {
        //否则跳转路由
        localStorage.setItem('case', Id)
        this.$router.push({ path })
      }
    },
    navigateToAbout(path, id) {
      var path = path
      var Id = id
      if (path == this.$route.path) {
        let toElement = document.getElementById(Id)
        toElement.scrollIntoView(true)
      } else {
        localStorage.setItem('toId', Id)
        this.$router.push({ path })
      }
    },
    routerPush(path) {
      if (path === this.$route.path) return
      this.$router.push({
        path,
      })
    },
  },
  async created() {
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;

  color: white;
  background-color: #383c50;

  .company-info-list {
    // height: 404px;
    // 25.25
    width: 100%;

    background-color: #383c50;
    // background-color: black;
    display: flex;
    // align-items: center;
    // justify-content: center;
    .info-left {
      width: 288px;

      // height: 340px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 16px;
      margin-left: 139px;

      margin-top: 40px;
      border-right: 1px white solid;
      .logo {
        width: 184px;
        height: 38px;
        background-image: url('../../assets/img/-h-睿法科技logo1.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 15px;
      }
      .phone-area {
        display: flex;
        .icon {
          width: 15px;
          height: 40px;
          img {
            width: 15px;
            height: 15px;
            margin-top: 5px;
          }
        }
        .phone {
          width: 200px;
          font-size: 16px;
          margin-left: 10px;
          .phone1 {
          }
          .phone2 {
            margin: 5px 0;
          }
        }
      }
      .address-area {
        display: flex;
        .icon {
          width: 15px;
          height: 40px;
          img {
            width: 15px;
            height: 15px;
            margin-top: 5px;
          }
        }
        .address {
          width: 200px;
          margin-bottom: 10px;
          margin-left: 10px;
        }
      }
      .we-code {
        display: flex;
        width: 202px;
        justify-content: space-between;
        margin-top: 15px;
        .left-code {
          display: flex;
          flex-direction: column;
          .rfkj {
            width: 80px;
            height: 80px;
          }
          .text {
            font-size: 12px;
            margin-top: 15px;
          }
        }
        .right-code {
          display: flex;
          flex-direction: column;
          margin-left: 25px;

          .mll {
            width: 80px;
            height: 80px;
          }
          .text {
            font-size: 12px;
            margin-top: 15px;
          }
        }
      }
    }
    .info-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 139px;
      margin-left: 60px;
      width: 800px;
      margin-top: 60px;
      .info-list {
        display: flex;

        justify-content: space-between;
        // gap: 130px;

        // height: 280px;
        .about-us {
          text-align: center;
          .title {
            font-size: 20px;
          }
          .nav-item {
            margin: 10px;
            li {
              margin: 10px;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }
        .solution {
          text-align: center;

          .title {
            font-size: 20px;
          }
          .nav-item {
            margin: 10px;
            li {
              margin: 10px;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }
        .example {
          text-align: center;

          .title {
            font-size: 20px;
          }
          .nav-item {
            margin: 10px;
            li {
              margin: 10px;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }
        .news-center {
          text-align: center;

          .title {
            font-size: 20px;
          }
          .nav-item {
            margin: 10px;
            li {
              margin: 10px;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .company-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:15px;
    .copyright {
      // font-size: 18px;
      text-align: center;
      margin-bottom: 10px;
    }
    .beian {
      // display: flex;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        position: relative;
        margin-right: 5px;
        top: -3px;
        width: 20px;
        height: 20px;
      }
    }
    .link{
      margin-bottom: 10px;
      .link-item{
        margin-right: 20px;
        cursor: pointer;
        &:hover{
          color: #1ed4e1;
        }
      }
    }
  }
}
</style>
