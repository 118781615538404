<template>
  <div class="intro">
    <div class="intro-title">
      <span class="zh-title" :class="{product1:title[2]}">{{ title[0] }}</span>
      <div class="line"></div>
      <span class="en-title">{{ title[1] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.intro {
  // margin-top: 30px;
}
.intro-title {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 900px;
  height: 60px;
  line-height: 60px;
  .zh-title {
    font-size: 30px;
  }
  .line {
    width: 2px;
    height: 35px;
    background-color: black;
    margin: 15px 10px;
  }
  .en-title {
    font-size: 30px;
    color: grey;
  }
}
</style>
